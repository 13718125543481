/* Login.css */

.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #0e0f12;
    padding-left: 20px;
    padding-right: 20px;
}

.login h1{
    color: #fff;
}

h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
}

.login-form {
    background-color: #0e0f12;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(255, 245, 245, 0.1);
    width: 100%;
    max-width: 400px;
}

.input-data {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.input-data label {
    font-size: 1rem;
    margin-bottom: 5px;
    color: #666;
}

.input-data input {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
}

.input-data input:focus {
    border-color: #6200ea;
}

.login-button {
    width: 100%;
    padding: 10px;
    background-color: #2ecc71;
    color: #fff;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-button:hover {
    background-color: #2ecc71;
}

.redirect-signup {
    display: block;
    margin-top: 20px;
    font-size: 0.9rem;
    text-align: center;
    color: #666;
}

.redirect-signup a {
    color: #2ecc71;
    text-decoration: none;
}

.redirect-signup a:hover {
    text-decoration: underline;
}
