/* General styles for the navigation */
nav {
    position: fixed;
    overflow: hidden;
    top: 0%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* padding: 1rem; */
    background-color: #0e0f12;
    width: 100vw;
    gap: 55vw;
    padding-top: 1.8vh;
    padding-bottom: 1.5vh;

}

nav .menu-items{
    display: flex;

}
nav .logo{
    padding-top: 10px;
    padding-left: 18px;
}

nav .menu-items a{
    font-size: 1.4rem;
    padding-right: 30px;
}

.logo img {
    width: 100px;
}

.nav-links {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.nav-links a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
}
.nav-links a:hover{
    text-decoration: none;
    color: #2ae745;
}

.nav-links .log {
    border: none;
    background-color: #2ae745;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    color: white;
    cursor: pointer;
}

.hamburger {
    display: none; /* Hide hamburger by default */
    cursor: pointer;
    flex-direction: column;
    gap: 5px;
}

.hamburger .bar {
    width: 25px;
    height: 3px;
    background-color: white;
}

.hamburger .cross {
    font-size: 24px;
    color: white;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
    /* Hide the normal nav-links on mobile and position them off-screen initially */

    .nav-links {
        /* position: absolute; */
        position: fixed;
        top: 60px;
        right: 0;
        height: 100vh;
        /* background: rgb(13, 7, 7); */
        background: linear-gradient(to right, #0e0f12,#0e0f12);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        max-width: 100%;
        width: 50%;
        overflow: hidden;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
        border-radius: 10px;
        /* height: 100%; */
    }

    .menu-items{
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;
    }

    span .cross{
        font-size: 400px;
    }
    nav .menu-items a{
        font-family: "Poppins", sans-serif;
        font-size: 1.4rem;
        margin-bottom: 50px;
        padding-bottom: 5px;
        font-weight: lighter;
    }
    /* nav .menu-items a:visited{
        color: #2ae745;
    } */
    }
    /* Show the links when the hamburger is clicked (menuOpen state) */
    .nav-links.active {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        transform: translateX(0);
    }

    /* Make the hamburger visible only on mobile */
    .hamburger {
        display: flex;
    }

    .logo img {
        width: 80px;
    }

    .hamburger {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
    }

    nav{

    }


/* Desktop styles */
@media (min-width: 768px) {
    /* Ensure normal desktop navigation is displayed */
    .hamburger {
        display: none; /* Ensure hamburger never shows on desktop */
    }

    .nav-links {
        display: flex;
        gap: 2rem;
        align-items: center;
        position: static;
        transform: none;
        height: auto;
        flex-direction: row;
    }

    
}


