/* Container */
/* *{
  font-family: "Poppins", sans-serif;
} */

.pnl-container {
  max-width: 900px;
  /* margin: 0 auto; */
  margin-top: 100px;
  background-color: #000000;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(169, 254, 42, 0.1);
}

/* Headings */
h2 {
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 25px;
}

h3 , h4{
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 10px;
}
/* .color{
  color: #555;
} */



/* Portfoliolinear-gd{
Section */
.portfolio {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.stock-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 40px;
  align-items: center;
  background-color: #181515;
  padding: 15px;
  border-radius: 8px;
  transition: box-shadow 0.3s ease;
}

.stock-item:hover {
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08);
}

.stock-item span {
  font-size: 12rem;
  /* color: #f1f1f1; */
}

.stock-item span:first-child {
  font-weight: lighter;
}

/* Buttons */
.sell-btn, 
.withdraw-btn {
  padding: 12px 25px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sell-btn {
  max-width: 100%;
  width: 55%;
  background-color: #e74c3c;
  color: #fff;
}

.sell-btn:hover {
  background-color: #c0392b;
}

.withdraw-btn {
  background-color: #2ecc71;
  color: #fff;
  margin-top: 20px;
}

.withdraw-btn:hover {
  background-color: #27ae60;
}

.withdraw-btn:disabled {
  background-color: #bbb;
  cursor: not-allowed;
}

/* Balance Section */
.balance-section {
  margin-top: 30px;
  text-align: center;
}

.balance-section h3 {
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 15px;
}

.balance-section h4 {
  font-size: 20px;
  color: #777;
  margin-bottom: 15px;
}

/* Withdrawal Popup */
/* .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
} */

/* .popup {
  background-color: #33333300;
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
} */

/* .popup h3 {
  border:1px solid #fff;
  font-size: 22px;
  color: #333;
  margin-bottom: 20px;
}

.popup input {
  width: 80%;
  height: 1.3%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.popup-buttons button {
  flex: 1;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.popup-buttons button:first-child {
  background-color: #2ecc71;
  color: #fff;
}

.popup-buttons button:last-child {
  background-color: #e74c3c;
  color: #fff;
}

.popup-buttons button:hover {
  opacity: 0.9;
}

.profit-loss {
  font-weight: bold;
}

.profit-value {
  color: green;
}

.loss-value {
  color: red;
} */

.moving-pnl {
  font-size: 16px;
  font-weight: bold;
}

/* When the value is positive or zero */
.positive {
  color: green;
}

/* When the value is negative */
.negative {
  color: red;
}



/* Responsive Design */
@media (max-width: 768px) {
  .stock-item {
    flex-direction: column;
    gap: 14px;
  }

  .position {
    font-size: 24px;
    display: flex;
    color: #fff;
  }

  .stock-item span {
    font-size: 25px;
    font-weight: lighter;
  }

  .trade-data{
    font-size: 1.1rem;
    /* margin-bottom: 20px; */
  }


  .profit-loss {
    font-size: 2rem;
    color: #fff;
    font-weight: lighter;
  }

  h3 {
    color: #fff;
    font-size: 10px;
  }

  .current-balance {
    color: #fff;
  }

  .actions {
    flex-direction: column;
    gap: 10px;
  }


  .data {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 15px;
  }

  .first-row {
    display: flex;
    gap: 150px;
    align-items: center;
  }

  .sec-row {
    display: flex;
    align-items: center;
    gap: 100px;
  }

  .aval-balance {
    color: #fff;
  }

  .position-window{
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
  }

  .Profit-Loss{
    background-color: #0e0f12;
    width: 60%;
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* box-shadow: 5px 5px #27ae5f50; */
    box-shadow: 0 0px 16px rgb(176, 188, 176);
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  overflow: hidden;
  }

  .total-name{
    color: #fff;
    margin-bottom: 0;
    letter-spacing: 0.1rem;
    
  }

  .moving-pnl{
    margin-top: 0;
    font-size: 20px;
    font-weight: 600;
  } 

  .moving-pnl {
    font-size: 19px;
    font-weight: bold;
  }
  
  /* When the value is positive or zero */
  .positive {
    color: green;
  }
  
  /* When the value is negative */
  .negative {
    color: rgb(187, 48, 48);
  }
  
  .sec-box{
    background-color: #0e0f12;
    
  }

  .sec-box p{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding: 5px;
    color: rgb(203, 201, 201);
  }

  /* .sec-box p span{
    padding-left: 80px;
  } */

  .stockName{
    margin-left: 100px;
    font-size: 18px;
  }

  .qunty{
    padding-left: 260px;
  }

  .buyPrice{
    padding-left: 200px;
  }

  .curr-price{
    padding-left: 230px;
  }

  .invst-amt{
    padding-left: 154px;
  }

  .positions-head{
    padding-top: 8%;
    padding-bottom: 5%;
  }

  .balance-folder {
    margin-top: 15px;
    width: 80%; /* Adjusts width to be responsive */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .leverage-balance {
    background-color: #cfd1d4c4;
    border-radius: 12px;
    padding: 16px;
    max-width: 340px; /* Adjust as needed */
    width: 100%; /* Makes it responsive */
    height: auto; /* Ensures it adjusts based on content */
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    font-family: "Arial", sans-serif;
  }
  
  .company1 {
    margin-bottom: 10px;
  }
  
  .company-name {
    font-size: 18px; /* Increased font size slightly */
    color: #333;
    margin-bottom: 2px;
    font-weight: bold;
    display: flex;
    padding-left: 12px;
    font-style: italic;
  }
  
  .company-name span {
    color: #28b328;
    font-size: 22px;
  }
  
  .forex-trade {
    font-size: 14px;
    color: #666;
    margin-top: 4px;
  }
  
  .balance {
    background-color: #333;
    border-radius: 8px;
    padding: 15px 15px; /* Adjusted padding for better spacing */
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* position: relative; */
    min-height: 40px; /* To ensure minimum height */
  }
  
  .balance span {
    font-size: 14px;
    color: #c7c7c7;
  }
  
  .balance p {
    font-size: 28px;
    margin: 0;
    font-weight: bold;
    color: #ffffff;
  }
  
  .balance::after {
    content: "→";
    /* position: absolute; */
    right: 75px;
    font-size: 18px;
    color: white;
  }

  .balance-updt{
    margin-left: 18%;
  }
}