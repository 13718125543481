/* Global Styles */
  .terms-container {
    max-width: 800px;
    margin: 50px auto;
    background-color: #f4ecec;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(248, 229, 229, 0.808);
  }
  
  h1 {
    font-size: 2.5rem;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 1.5rem;
    color: #333;
    margin-top: 20px;
  }
  
  p, ul {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
    margin: 10px 0;
  }
  
  ul {
    padding-left: 20px;
  }
  
  a {
    color: #f4ecec;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .terms-container {
      margin-top:120px;
      padding: 15px;
    }
  
    h1 {
      font-size: 2rem;
    }
  
    h2 {
      font-size: 1.3rem;
    }
  
    p, ul {
      font-size: 0.9rem;
    }
  }
  