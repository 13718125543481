/* General Layout */
.plans-container {
  padding: 40px 20px;
  text-align: center;
  background-color: #0e0f12;
  overflow-x: auto; /* Enable horizontal scroll */
}

.plans-title {
  font-size: 36px;
  font-weight: bold;
  background: linear-gradient(to right, rgb(16, 174, 16), rgb(223, 223, 223));
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 50px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: lighter;
}

.plans-table {
  width: 100%;
  min-width: 900px; /* Minimum width to allow scrolling */
  margin: 0 auto;
  border-collapse: collapse;
  background-color: #0e0f12;
  box-shadow: 0 0px 34px green;
  border: 1px solid #28a745;
  border-radius: 10px;
  overflow: hidden;
}

.plans-table th,
.plans-table td {
  padding: 20px;
  border-bottom: 1px solid #dddddd;
  font-size: 18px;
  color: white;
}

.plans-table tr {
  background-color: #0e0f12;
}

.plans-table tr td {
  justify-content: center;
  align-items: center;
}

.plans-table th {
  background-color: rgb(3, 25, 34);
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
}

.plans-table tr:last-child td {
  border-bottom: none;
}

.plans-table td {
  background-color: #0e0f12;
}

.plans-table tr:hover td {
  background-color: #0e0f12;
}

/* Buttons */
.buy-now-btn {
  padding: 12px 25px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.buy-now-btn:hover {
  background-color: #218838;
  transform: scale(1.05);
}

.buy-now-btn.disabled {
  background-color: grey;
  cursor: not-allowed;
  transform: none;
}

/* Popup */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.qr-background{
  background-color: #0e0f1200;
}

.popup-content {
  position: relative;
  background-color: #00000000;
  padding: 40px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  transition: transform 0.3s ease;
  transform: scale(1);
  opacity: 1;
}

.popup-content h2 {
  font-size: 26px;
  margin-bottom: 20px;
  color: #333;
}

.popup-content p {
  font-size: 18px;
  color: #666;
  margin-bottom: 20px;
}

.qr-image {
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 5px solid #ddd;
}

.upi-img {
  width: 68vw;
  height: 18vw;
  margin-bottom: 20px;
  border-radius: 10px;
}

/* Popup Buttons */
.popup .popup-btn,
.popup .cancel-btn {
  margin: 15px 10px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.popup .popup-btn {
  background-color: #28a745;
  color: white;
}

.popup .popup-btn:hover {
  background-color: #218838;
  transform: scale(1.05);
}

.qr-h2 {
  color: #fff;
}

.qr-p {
  color: #fff;
  font-size: 18px;
}

.qr-pq {
  font-size: 20px;
  font-weight: 500;
}

.popup .cancel-btn {
  position: absolute;
  top: 100%;
  right: 40%;
  background-color: #dc354600;
  font-size: 30px;
  color: rgb(255, 246, 246);
}

.popup .cancel-btn:hover {
  background-color: #c8233300;
  transform: scale(1.05);
}

/* Responsive Design */
@media (max-width: 768px) {

  .plans-title {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .plans-table th, .plans-table td {
    padding: 10px;
    font-size: 14px;
  }

  .buy-now-btn {
    padding: 10px 20px;
    font-size: 14px;
  }

  .popup-content {
    padding: 15px;
  }

  .popup .cancel-btn {
    position: absolute;
    top: 3%;
    right: 2%;
    background-color: #dc3546;
    font-size: 18px;
    color: white;
    
  }

  .popup-content h2 {
    font-size: 22px;
  }

  .popup-content p {
    font-size: 16px;
  }

  .pay-here{
    font-size: 20px;
    color: #fffbfb;
  }

  .input-num{
    width: 10px;
  }

  .popup input{
    width: 80%;
  }

  .upi-logo{
    border-radius: 0%;
  }
}
