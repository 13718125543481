.watchlist-container {
  display: flex;
  height: 90vh;
  width: 100vw;
  padding-top: 12vh;
}

.sidebar {
  background-color: #0e0f12;
  color: white;
  /* padding: 20px; */
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.sidebar h2 {
  text-align: center;
  margin-bottom: 20px;
}

.currency-opt {
  background: linear-gradient(to right, rgb(16, 174, 16), rgb(223, 223, 223));
  background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-top: 9px;
}

.options {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  gap: 18px;
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 10px;
  background-color: #333;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.option:hover {
  background-color: #444;
}

.graph {
  background-color: #b6303d;
}

.buy-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.buy-btn:hover {
  background-color: #0056b3;
}

/* .leverage-balance{
  border: 1px solid white;
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
  margin-top: 20px;
  background-color: aliceblue;
}

.company1{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0;
}

.company1 h1{
  font-weight: 600;
}

.company-name{
  font-size: 18px;
  margin-top: 6px;
  font-style: italic;
  font-weight: 700;

}

.company-name span{
  color: #2ecc07;
  font-size: 22px;
}

.balance {
  border: 2px solid rgb(0, 0, 0);
  border-radius: 5px;
  background-color: #0e0f12;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
} */

.balance-folder {
  margin-top: 15px;
  width: 80%; /* Adjusts width to be responsive */
  display: flex;
  justify-content: center;
  align-items: center;
}

.leverage-balance {
  background-color: #cfd1d4c4;
  border-radius: 12px;
  padding: 16px;
  max-width: 340px; /* Adjust as needed */
  width: 100%; /* Makes it responsive */
  height: auto; /* Ensures it adjusts based on content */
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  font-family: "Arial", sans-serif;
}

.company1 {
  margin-bottom: 10px;
}

.company-name {
  font-size: 18px; /* Increased font size slightly */
  color: #333;
  margin-bottom: 2px;
  font-weight: bold;
  display: flex;
  padding-left: 12px;
  font-style: italic;
}

.company-name span {
  color: #28b328;
  font-size: 22px;
}

.forex-trade {
  font-size: 14px;
  color: #666;
  margin-top: 4px;
}

.balance {
  background-color: #333;
  border-radius: 8px;
  padding: 15px 15px; /* Adjusted padding for better spacing */
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* position: relative; */
  min-height: 40px; /* To ensure minimum height */
}

.balance span {
  font-size: 14px;
  color: #c7c7c7;
}

.balance p {
  font-size: 28px;
  margin: 0;
  font-weight: bold;
  color: #ffffff;
}

.balance::after {
  content: "→";
  /* position: absolute; */
  right: 75px;
  font-size: 18px;
  color: white;
}

/* popup */

/* .buy-stocks{
  position: fixed;
  
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: #0056b3;
  height: 60%;
  width: 90%;
  left: 4%;
  top: 20%;
} */

.buy-stocks {
  position: fixed;
  top: 20%;
  left: 10%;
  width: 80%;
  height: 50%;
  background-color: #444545; /* Semi-transparent background */
  display: flex;
  /* border: 2px solid black; */
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  z-index: 1000; /* Ensure it appears on top */
}

.selected-stocks {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 25px;
  background-color: #a2a3a7;
  border: 1px solid #edeaea;
  border-radius: 10px;
  color: #0e0f12;
  padding: 10px 15px;

  box-shadow: 1px 2px 5px 3px #d7dbd9a6;

}

.stock-name {
  font-size: 18px;
  font-weight: 800;
  color: #161616;
}

.invested-bal{
  color: white;
  margin-bottom: 35px;
}

.quant-bal {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.quant-bal p {
  font-size: 16px;
  color: #666;
}

.inv-update {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 75px;
  color: white;
  border-top: 1px solid white;
  padding-top: 15px;
}

.inv-update p {
  font-size: 16px;
  color: #f4f2f2;
}

.purchage-cancel {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  gap: 35px;
}

.purchage-cancel button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.purchage-cancel button[disabled] {
  background-color: #ccc;
}

.purchage-cancel button:last-child {
  background-color: #ff4d4d;
}

.purchage-cancel button:hover:not([disabled]) {
  background-color: #0056b3;
}


  /* Graph Component Styles */
  .graph-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    animation: slideIn 0.5s forwards;
  }

  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    background: transparent;
    color: white;
    font-size: 24px;
    border: none;
    cursor: pointer;
    background-color: #b6303d;
  }
