/* src/PrivacyPolicy.css */

.privacy-policy-container {
    max-width: 768px;
    margin: 20px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.header {
    text-align: center;
    margin-bottom: 20px;
}

h1 {
    font-size: 2.5em;
    margin: 0;
    color: #333;
    margin-bottom: 20px;
}

h2 {
    font-size: 1.8em;
    color: #333;
    margin-top: 20px;
}

p {
    line-height: 1.6;
    margin: 10px 0;
}

ul {
    margin: 10px 0 20px 20px;
}

li {
    margin: 5px 0;
}

@media (max-width: 768px) {
    .privacy-policy-container {
        margin-top: 120px;
        padding: 15px;
    }

    h1 {
        font-size: 2em;
    }

    h2 {
        font-size: 1.5em;
    }
}
