.admin-dashboard-container {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
    color: #ffffff;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.done-bttn{
    font-size: 20px;
}

table th, table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

input {
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

button {
    padding: 5px 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}

.add-stock-section {
    margin-bottom: 20px;
}

.add-stock-section input {
    margin-right: 10px;
}

h2 {
    margin-bottom: 10px;
}
