/* Signup.css */

.signup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #0e0f12;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 18vh;
}

.signup h1{
    color: #fff;
}

h1 {
    font-size: 2.5rem;
    color: #ffffff;
    margin-bottom: 20px;
}

.signup-form {
    background-color: #0e0f12;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.input-data {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.input-data label {
    font-size: 1rem;
    color: #666;
    margin-bottom: 5px;
}

.input-data input {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    outline: none;
    transition: border-color 0.3s ease;
}

.input-data input:focus {
    border-color: #28a745;
}

.signup-button {
    width: 100%;
    padding: 10px;
    background-color: #28a745;
    color: #fff;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.signup-button:hover {
    background-color: #218838;
}

.redirect-login {
    display: block;
    margin-top: 20px;
    font-size: 0.9rem;
    text-align: center;
    color: #666;
}

.redirect-login a {
    color: #28a745;
    text-decoration: none;
}

.redirect-login a:hover {
    text-decoration: underline;
}
